<template>
  <v-card class="full-height">
    <v-card-title>{{$t('t.Promises')}}</v-card-title>
    <v-card-text
      class="card-text-full-height"
      v-if="promisesData"
    >
      <div class="d-flex flex-wrap full-height">
        <div class="d-flex flex-column flex-grow-1">
          <div class="d-flex">
            <div class="flex-basis-50 text-center">
              <div class="font-weight-bold text-h6">{{promisesData.promisesAmount|currency}}</div>
              <div>{{promisesData.promisesCount}} {{$t('t.ActivePromises')}}</div>
            </div>
            <div class="flex-basis-50 text-center">
              <span class="text-h6"><span class="font-weight-bold">{{Math.round(promisesData.promisesHeld) || 0}}%</span> {{$t('t.PromisesKept')}}</span>
            </div>
          </div>
          <v-spacer />
          <div style="max-height: 12vh;">
            <graph
              v-if="promisesData.promisesCoverage"
              :options="barOptions"
              :series="barSeries"
              width='95%'
              height='100%'
              type="bar"
            />
          </div>
          <v-spacer />
        </div>
        <v-stepper
          vertical
          v-if="installments"
          class="transparent py-4 d-flex flex-column overflow-y-auto"
          style="box-shadow: unset"
        >
          <v-stepper-step
            :complete="true"
            step=""
            class="pt-0 pb-4"
          >
            <div v-if="installments.previous.date">
              <div class="font-weight-bold pb-1">{{installments.previous.amount | currency}}</div>
              <small>{{installments.previous.date | date}}</small>
            </div>
            <div v-else>
              {{$t('t.NoPaidInstallments')}}
            </div>
          </v-stepper-step>
          <v-stepper-content
            step=""
            class="py-2"
          />
          <v-stepper-step
            :complete="false"
            step=""
            class="pt-6 pb-4"
          >
            <div v-if="installments.today.amount">
              <div class="font-weight-bold pb-1">{{installments.today.amount | currency}}</div>
              <small>{{$t('t.Today')}}</small>
            </div>
            <div v-else>
              {{$t('t.Today')}}
            </div>
          </v-stepper-step>
          <v-stepper-content
            step=""
            class="py-2"
          />
          <v-stepper-step
            :complete="false"
            step=""
            class="pt-6 pb-0"
          >
            <div v-if="installments.next.date">
              <div class="font-weight-bold pb-1">{{installments.next.amount | currency}}</div>
              <small>{{installments.next.date | date}}</small>
            </div>
            <div v-else>
              {{$t('t.NoUpcomingInstallments')}}
            </div>
          </v-stepper-step>
        </v-stepper>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    Graph: () => import('@/components/graph')
  },
  props: {
    promisesData: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    barSeries () {
      return [{
        name: this.$t('t.Promised'),
        data: [Math.round(this.promisesData?.promisesCoverage.promised ?? 0)]
      },
      {
        name: this.$t('t.Disputed'),
        data: [Math.round(this.promisesData?.promisesCoverage.disputed ?? 0)]
      },
      {
        name: this.$t('t.Due'),
        data: [Math.round(this.promisesData?.promisesCoverage.due ?? 0)]
      }]
    },
    barOptions () {
      return {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%'
        },
        fill: {
          opacity: 1
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: -8
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        tooltip: {
          y: {
            formatter: (v) => this.formatPercentage(v)
          }
        },
        xaxis: {
          categories: [this.$t('t.GraphInvoicesBalanceCoverage')],
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false
          }
        },
        yaxis: {
          forceNiceScale: false,
          show: false,
          showAlways: false
        }
      }
    },
    installments () {
      return this.promisesData?.installments
    }
  }
}
</script>

<style lang="stylus" scoped>
.flex-basis-50
  flex-basis 50%

.full-height
  height 100%

.card-text-full-height
  height calc(100% - 64px)
</style>
